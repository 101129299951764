import { Button } from "@mui/material"
import { Container } from "@mui/system"
import i18next from "i18next"
import * as React from "react"
import Layout from "../components/Layout"
import PageHeading from "../components/PageHeading"
import Seo from "../components/Seo"
import withTrans from "../components/withTrans"
import theme from "../theme"
const NotFoundPage = ({ t }) => {
  return (
    <Layout pageName="404" theme={theme}>
      <Container>
        <PageHeading title={t("404.heading_1")}>
          {t("404.text")}
          <br />
          <br />
          <Button
            mt={6}
            variant="contained"
            color="secondary"
            disableElevation
            sx={{
              backgroundColor: "#099250",
              color: "#fff",
              fontWeight: "600",
            }}
            href={"/"}
          >
            {t("404.button")}
          </Button>
        </PageHeading>
        {/* 
        <ClusterCategoryTeaser
          title={intl.formatMessage({ id: "404.suggestions" })}
          categories={[]}
        />
        */}
      </Container>
    </Layout>
  )
}

export default withTrans(NotFoundPage)

export const Head = () => <Seo title={i18next.t("404.title")} />
